// Generated by Framer (2add0ca)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ApcsuQA0Y"];

const variantClassNames = {ApcsuQA0Y: "framer-v-1ke477v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; coverImage?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ApcsuQA0Y", coverImage: PAHH4E2KA, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ApcsuQA0Y", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-A9Jmg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(304px, 100vw)", ...toResponsiveImage(PAHH4E2KA)}} className={cx("framer-1ke477v", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ApcsuQA0Y"} ref={ref} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-A9Jmg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-A9Jmg .framer-18kf2bb { display: block; }", ".framer-A9Jmg .framer-1ke477v { height: 240px; position: relative; width: 304px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 240
 * @framerIntrinsicWidth 304
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PAHH4E2KA":"coverImage"}
 */
const FramerzHYzmV5SS: React.ComponentType<Props> = withCSS(Component, css, "framer-A9Jmg") as typeof Component;
export default FramerzHYzmV5SS;

FramerzHYzmV5SS.displayName = "List item";

FramerzHYzmV5SS.defaultProps = {height: 240, width: 304};

addPropertyControls(FramerzHYzmV5SS, {PAHH4E2KA: {title: "Cover Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerzHYzmV5SS, [])